import Script from 'next/script';
import { useEffect } from 'react';

const mopinionID = 'ONuYWSYfJm5vUYcZcz4dH9mmgnI9RwiQMsQLgwXP';

export type MopinionSRV = {
	ALL_KEYS: Array<string>;
	/** Boolean show is ignored by Mopinion */
	openModal: (show: boolean, id: string) => void;
	closeModal: (id: string) => void;
};

declare global {
	interface Window {
		Pastease?: {
			load: (id: string) => void;
		};
		srv?: MopinionSRV;
	}
}
/**
 * 1. Insert mopinion script
 * 2. Wait for script to be loaded
 * 3. Load Mopinion deployment
 * 4. Wait for deployment to be loaded
 * 5. Safe srv object for parent component to use
 */
export const LoadMopinion = ({
	setSRV,
}: {
	setSRV: (srv: MopinionSRV) => void;
}) => {
	useEffect(() => {
		if (window.srv != null) {
			setSRV(window.srv);
			return;
		}

		const abortController = new AbortController();

		// 4. Wait for deployment to be loaded
		document.addEventListener(
			'mopinion_loaded',
			() => {
				if (window.srv != null) {
					// 5. Safe srv object for parent component to use
					setSRV(window.srv);
					abortController.abort();
				}
			},
			{ signal: abortController.signal }
		);

		return () => {
			abortController.abort();
		};
	}, [setSRV]);

	if (window.srv != null) return null;

	return (
		// 1. Insert mopinion script
		<Script
			src="https://deploy.mopinion.com/js/pastease.js"
			onLoad={() => {
				// 2. Wait for script to be loaded
				if (
					'Pastease' in window &&
					window.Pastease != null &&
					typeof window.Pastease === 'object' &&
					'load' in window.Pastease &&
					typeof window.Pastease.load === 'function'
				) {
					// 3. Load Mopinion deployment
					window.Pastease.load(mopinionID);
				}
			}}
		/>
	);
};
