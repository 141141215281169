import { EventData } from '../pushEvent';

export const getCleanDataLayerEvent = (): EventData => {
	return {
		event: 'interaction.cleanup',
		events: { category: undefined, action: undefined, label: undefined },
		page: {
			type: undefined,
			environment: undefined,
			opPartner: undefined,
			wordCount: undefined,
			title: undefined,
			breadcrumbs: undefined,
			id: undefined,
			portfolio: undefined,
			ct: undefined,
			creationDate: undefined,
			opContentType: undefined,
			opOnderwerp: undefined,
			opBranche: undefined,
			opRegio: undefined,
			opThema: undefined,
			opExternalContent: undefined,
			urlPath: undefined,
			sdg: undefined,
			lastChecked: undefined,
		},
	};
};
