'use client';

import { useEffect, useRef } from 'react';
import { locationDataLoaded } from '../routing/location/locationChangeActions';
import { usePageHistory } from '../pageWrappers/usePageHistory';
import {
	WebApi404Data,
	WebApiPageData,
} from '../dataCollector/types/dataCollector.types';
import { PageCollection } from '../dataCollector/pageDataCollector/types/pageDataCollector.types';
import { useDOPDispatch } from './reduxHooks';
import { usePathname } from 'next/navigation';

export const UseDispatchLocationDataLoaded = ({
	initialData,
}: {
	initialData: PageCollection<WebApiPageData | WebApi404Data>;
}) => {
	const dataRef = useRef<unknown | undefined>(undefined);
	const [, dispatchPageHistory] = usePageHistory();
	const dispatch = useDOPDispatch();
	const pathname = usePathname();

	useEffect(() => {
		// Only fire dispatch if the data has changed
		if (dataRef.current === initialData) {
			return;
		}
		dataRef.current = initialData;
		dispatch(
			locationDataLoaded({
				status: 200,
				data: initialData,
			})
		);

		const { page } = initialData;
		if (page.status === '404') {
			dispatchPageHistory({
				partners: undefined,
				providerType: undefined,
				pathname,
			});
		} else {
			dispatchPageHistory({
				partners: page.metadata?.['op:partner'],
				providerType: page.providerType,
				pathname: page.url,
			});
		}
	}, [dispatch, dispatchPageHistory, initialData, pathname]);
	return null;
};
