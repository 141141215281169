import environmentConfig from 'moduleAlias/environmentConfig';

import { validateSdgProductData } from '@/app/sdgArticle/_helpers/validateSdgProductData';
import { PageCollection } from '@/globals/dataCollector/pageDataCollector/types/pageDataCollector.types';
import { WebApiPageData } from '@/globals/dataCollector/types/dataCollector.types';
import { BaseWebApiData } from '@/globals/webApiTypes/base.types';

const getSdgMetaData = (page: WebApiPageData) => {
	if ('sdg' in page) {
		const policyCode =
			page.sdg?.policies?.map((policy) => policy.code).join(';') ?? '';
		const typeOfService = page.sdg?.services?.join(';') ?? '';

		return {
			pageTagged: true,
			countryCode: 'NLD',
			policyCode,
			typeOfService,
		};
	} else {
		return { pageTagged: false };
	}
};

const getExternalContentType = ({ page, sdg }: PageCollection) => {
	const transactionTypeMapping = {
		gemeente: 'sdg-gemeente',
		municipality: 'sdg-gemeente',
		provincie: 'sdg-provincie',
		province: 'sdg-provincie',
		waterschap: 'sdg-waterschap',
		['water-authority']: 'sdg-waterschap',
	} as const;

	const sdgProduct = sdg?.product;

	const transactionType =
		'transactionBlock' in page &&
		'key' in page.transactionBlock?.filter?.activeFilters
			? (page.transactionBlock?.filter?.activeFilters
					.key as keyof typeof transactionTypeMapping)
			: null;

	return transactionType != null &&
		transactionType in transactionTypeMapping &&
		validateSdgProductData(sdgProduct)
		? transactionTypeMapping[transactionType]
		: 'none';
};

const getTags = (tags: BaseWebApiData['metadata']['tags'], prefix: string) => {
	if (tags == null) return '';

	return tags
		.filter((tag) => tag.startsWith(prefix))
		.map((tag) => tag.replace(prefix, ''))
		.join(';');
};

const getContentTags = (tags: BaseWebApiData['metadata']['tags']) => {
	const contentTags = {
		cl: getTags(tags, 'cl:'),
		lg: getTags(tags, 'lg:'),
		ds: getTags(tags, 'ds:'),
		cp: getTags(tags, 'cp:'),
	};

	return contentTags;
};

// eslint-disable-next-line complexity
export const getAnalyticsPageAttributes = ({ page, sdg }: PageCollection) => {
	const metadata = page.metadata;
	const partners = metadata?.['op:partner'] ?? [];
	const breadcrumbs =
		page.breadcrumbs?.linkList?.slice(1).map((breadcrumb) => {
			if (typeof breadcrumb.text === 'string') {
				return breadcrumb.text.toLowerCase();
			}
			return breadcrumb.text.name.toLowerCase();
		}) ?? [];
	const sdgMetaData = getSdgMetaData(page);
	const opExternalContent = getExternalContentType({ page, sdg });

	return {
		environment: environmentConfig.env,
		type: page.providerType ?? '',
		opPartner: partners.join('|'),
		wordCount: page.wordCount ?? '',
		title: page.windowTitle ?? '',
		breadcrumbs,
		id: metadata?.['dcterms:identifier'] ?? '',
		portfolio: page.portfolio ?? '',
		ct: page.contentTheme ?? 'Geen',
		creationDate: metadata?.['dcterms:created'] ?? '',
		opContentType: metadata?.['op:contentType'] ?? page.providerType ?? '',
		opPageType: metadata?.['op:pageType'] ?? '',
		opOnderwerp: metadata?.['op:onderwerp']?.join(';') ?? '',
		opBranche: metadata?.['op:branche']?.join(';') ?? '',
		opRegio: metadata?.['op:regio']?.join(';') ?? '',
		opThema: metadata?.['op:insightsThemas']?.join(';') ?? '',
		opExternalContent, // if external content like SDG-api is shown
		sdg: sdgMetaData,
		lastChecked: metadata?.['lastChecked'],
		urlPath: page.url ?? window.location.href,
		...getContentTags(metadata?.tags),
	};
};
