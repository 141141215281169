import React from 'react';

import { pushSingleEvent } from './analyticsHelpers';

const triggerPoints = [0.125, 0.25, 0.5, 0.75, 1] as const;
const averageWordsPerMS = 250 / 60 / 1000;
const interactionThreshold = 30000;

/**
 * On pages with a wordcount measure what percentage of the average required
 * time to read the article the user has been on the page.
 *
 * Send updates to GA at specified triggerPoints as long as user us actively engaged on a page.
 * When user is inactive for more than 30000 ms, we consider user as inactive and no longer engaging with the page.
 */

export const useMeasureReadingIntensity = ({
	urlPath,
	wordCount,
}: {
	urlPath: string;
	wordCount: number | undefined;
}) => {
	React.useEffect(() => {
		if (wordCount == null) return;
		let elapsedTime = 0;
		let lastInteractedTimestamp = performance.now();
		let lastFrameTimestamp: DOMHighResTimeStamp | undefined = performance.now();
		let frameTimer: number;
		const firedEvents: number[] = [];
		const articleReadingTimeMS = wordCount / averageWordsPerMS;

		const runFrame = (frameTimestamp: DOMHighResTimeStamp) => {
			const isStopped =
				document.visibilityState === 'hidden' ||
				frameTimestamp - lastInteractedTimestamp > interactionThreshold;
			if (!isStopped && lastFrameTimestamp != null) {
				const frameTimeDelta = frameTimestamp - lastFrameTimestamp;

				elapsedTime += frameTimeDelta;

				for (const triggerPoint of triggerPoints) {
					if (
						elapsedTime >= articleReadingTimeMS * triggerPoint &&
						!firedEvents.includes(triggerPoint)
					) {
						firedEvents.push(triggerPoint);
						pushSingleEvent({
							event: 'interaction.leesIntensiteit',
							events: {
								category: 'interaction.leesIntensiteit',
								action: urlPath,
								label: `${triggerPoint * 100}%`,
							},
						});
					}
				}
			}

			lastFrameTimestamp = frameTimestamp;

			if (firedEvents.length < triggerPoints.length) {
				frameTimer = requestAnimationFrame(runFrame);
			}
		};

		frameTimer = requestAnimationFrame(runFrame);

		const setLastInteractedTimestamp = () => {
			lastInteractedTimestamp = performance.now();
		};

		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				setLastInteractedTimestamp();
				return;
			}

			lastFrameTimestamp = undefined;
		};

		const abortEvents = new AbortController();
		window.addEventListener('click', setLastInteractedTimestamp, {
			signal: abortEvents.signal,
		});
		window.addEventListener('mousemove', setLastInteractedTimestamp, {
			signal: abortEvents.signal,
		});
		window.addEventListener('keydown', setLastInteractedTimestamp, {
			signal: abortEvents.signal,
		});
		window.addEventListener('scroll', setLastInteractedTimestamp, {
			signal: abortEvents.signal,
		});
		window.addEventListener('visibilitychange', handleVisibilityChange, {
			signal: abortEvents.signal,
		});

		return () => {
			abortEvents.abort();

			cancelAnimationFrame(frameTimer);
		};
	}, [wordCount, urlPath]);
};
