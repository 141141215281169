import { SDGSchema } from '@/globals/dataCollector/sdgDataCollector/types/sdgApiSchemas.types';

// Perform number of checks to see if this product is suitable to show in a sdg-article, or if we need to fallback to a regular article
export const validateSdgProductData = (
	product: SDGSchema<'Product'> | undefined
): boolean => {
	if (product == null) return false;
	if (product.productValtOnder?.upnUri != null) return false;
	if (product.productAanwezig !== true) return false;

	return true;
};
