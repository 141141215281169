import * as v from 'valibot';
import { trySync } from '../helpers/try';

const validateMaxAge = (dateSet: Date, maxAgeInMS: number) => {
	return dateSet.getTime() + maxAgeInMS >= Date.now();
};

// When max age is not provided, no max age is assumed
const getExpirableLocalStorageItem = <Type,>({
	key,
	maxAgeInMS,
	schema,
}: {
	key: string;
	maxAgeInMS: number;
	schema: v.BaseSchema<unknown, Type, v.BaseIssue<unknown>>;
}) => {
	const expirableSchema = v.object({
		value: schema,
		dateSet: v.pipe(v.string(), v.isoTimestamp()),
	});
	try {
		const item = trySync(() => {
			const json = localStorage.getItem(key);

			if (json == null) return null;

			return JSON.parse(json);
		});
		if (!item.success) return null;

		const parsed = v.safeParse(expirableSchema, item.result);

		if (!parsed.success) {
			try {
				localStorage.removeItem(key);
			} catch (error) {
				// NOOP
			}
			return null;
		}
		const { dateSet: dateSetISO8601, value } = parsed.output;
		const dateSet = new Date(dateSetISO8601);

		if (!validateMaxAge(dateSet, maxAgeInMS)) {
			try {
				localStorage.removeItem(key);
			} catch (error) {
				// NOOP
			}
			return null;
		}

		return value ?? null;
	} catch (e) {
		return null;
	}
};

const setExpirableLocalStorageItem = <Type,>({
	key,
	value,
}: {
	key: string;
	value: Type;
}) => {
	try {
		const dateSet = new Date(Date.now()).toJSON();
		const item = JSON.stringify({ value, dateSet });

		localStorage.setItem(key, item);
	} catch (e) {
		/* NOOP */
	}
};

export const createExpirableLocalStorage = <Type,>({
	key,
	maxAgeInMS,
	schema,
}: {
	key: string;
	maxAgeInMS: number;
	schema: v.BaseSchema<unknown, Type, v.BaseIssue<unknown>>;
}) => {
	return {
		get: () => getExpirableLocalStorageItem({ key, maxAgeInMS, schema }),
		set: (value: Type) => setExpirableLocalStorageItem({ key, value }),
	};
};
