'use client';

import { pushEvent } from '../pushEvent';
import { getAnalyticsPageAttributes } from '../helpers/getAnalyticsPageAttributes';
import { SDGData } from '@/globals/dataCollector/sdgDataCollector/types/sdgApiCollector.types';
import { WebApiPageData } from '@/globals/dataCollector/types/dataCollector.types';
import { useEffect, useRef } from 'react';
import { getCleanDataLayerEvent } from '../helpers/getCleanDataLayerEvent';
import { useMeasureReadingIntensity } from '@dop/shared/analytics/readingIntensityAnalytics';
import {
	useReferrer,
	useVisitedPartners,
} from '@/globals/pageWrappers/usePageHistory';
import { PageCollection } from '@/globals/dataCollector/pageDataCollector/types/pageDataCollector.types';
import { useIncrementFeedbackPageCount } from '@/sections/pageFeedback/usePageFeedbackPopup';
import { usePathname } from 'next/navigation';

const pushPageviewEvent = ({
	page,
	sdg,
	visitedPartners = new Set(),
	referrer = '',
}: {
	page: WebApiPageData;
	sdg: SDGData;
	visitedPartners: Set<string> | undefined;
	referrer: string | undefined;
}) => {
	const pageAttributes = getAnalyticsPageAttributes({ page, sdg });
	const partners = page.metadata?.['op:partner'] ?? [];

	if (referrer !== '') pushEvent(getCleanDataLayerEvent());

	pushEvent({
		events: {
			category: 'interaction.pageview',
		},
		page: { ...pageAttributes, referrer },
		user: {
			opPartnerList: [...new Set([...visitedPartners, ...partners])],
		},
	});
};

export const usePushPageviewEvent = (data: {
	page: WebApiPageData;
	sdg?: SDGData;
}) => {
	const savedForPageRef = useRef<string>();
	const pathname = usePathname();

	const visitedPartners = useVisitedPartners();
	const referrer = useReferrer();
	useEffect(() => {
		// Only fire page view event if the data has changed
		if (savedForPageRef.current === pathname) {
			return;
		}
		savedForPageRef.current = pathname;
		const { page, sdg } = data;

		pushPageviewEvent({
			page,
			sdg,
			visitedPartners,
			referrer,
		});
	}, [data, pathname, referrer, visitedPartners]);
};

/**
 * Should come before other components to make sure
 * the pageview is counted before other effects
 */
export const LogPageviewEvent = ({
	data,
}: {
	data: PageCollection<WebApiPageData>;
}) => {
	useIncrementFeedbackPageCount();
	usePushPageviewEvent(data);
	useMeasureReadingIntensity({
		urlPath: data.page.url,
		wordCount: data.page.wordCount,
	});
	return null;
};
