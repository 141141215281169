'use client';

import { chevronIcon } from '@/uiIcons/chevronIcon.css';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { box } from '@/uiPrimitives/layout/box';
import { icon } from '@/uiPrimitives/media/icon';
import { stack } from '@/uiPrimitives/layout/stack';
import {
	styledToTopTextBlock,
	styledToTopButton,
	styledToTopText,
	styledToTopWrapper,
} from './ToTop.css';
import { Translation } from '@/globals/translate/translations';
import { textBlock } from '@/uiPrimitives/typography/text';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { position } from '@/uiPrimitives/layout/position';
import { DOPLink } from '@/uiComposites/interactive/DOPLink';
import { occupy } from '@/uiPrimitives/layout/occupy';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { zIndex } from '@/uiPrimitives/layout/zIndex.css';
import { pageGrid } from '@/uiPrimitives/layout/pageGrid/pageGrid';
import { pageGridItem } from '@/uiPrimitives/layout/pageGrid/pageGridItem';

export const ToTop = ({ text }: { text: Translation<'to top'> }) => {
	return (
		<div
			className={classes(
				styledToTopWrapper,
				pageGrid({ columnCount: '12-of-12' })
			)}
		>
			<DOPLink
				href={'#'}
				data-link-type="toTop-link"
				className={classes(
					pageGridItem({
						gridColumnStart: 'span 1',
						justifySelf: 'end',
					}),
					box({
						backgroundColor: 'positiveProjectBrand',
					}),
					zIndex({ zIndex: 'toTop' }),
					styledToTopButton
				)}
				onClick={(event) => {
					event.preventDefault();
					window.scrollTo({ top: 0 });
					document.body.focus({ preventScroll: true });
				}}
				{...addTestSelector('uiToTopButton')}
			>
				<div
					className={classes(
						shelf({
							alignItems: 'center',
						}),
						position({ position: 'relative' }),
						box({
							padding: '-2 | smaller',
						})
					)}
				>
					<div
						className={classes(
							occupy({ blockSize: 'capSize', inlineSize: 'capSize' })
						)}
					>
						<div className={classes(stack({ gap: '0px' }))}>
							<i
								aria-hidden
								className={classes(
									icon({
										icon: chevronIcon,
										adjustIcon: 'rotate90degFlipHorizontal',
										blockSize: 'auto',
										inlineSize: '-2 | smaller',
									})
								)}
							/>
							<i
								aria-hidden
								className={classes(
									icon({
										icon: chevronIcon,
										adjustIcon: 'rotate90degFlipHorizontal',
										blockSize: 'auto',
										inlineSize: '-2 | smaller',
									})
								)}
							/>
						</div>
					</div>
					<div className={classes(styledToTopTextBlock)}>
						<div
							className={classes(
								textBlock({
									textDecorationLine: 'none',
								}),
								box({
									padding: '-2 | smaller',
								}),
								styledToTopText
							)}
						>
							{text}
						</div>
					</div>
				</div>
			</DOPLink>
		</div>
	);
};
